<template>
  <v-dialog v-model="show" width="500" persistent>
    <v-card>
      <v-card-text class="dialog_message"> Criar Grupo </v-card-text>
      <v-card-text>
        <div class="flex-grow-1"></div>
        <v-text-field
          ref="piecesToPackInput"
          v-model="name"
          label="Nome do Grupo"
        />
        <v-select
          multiple
          item-value="_id"
          item-text="humanReadableName"
          v-model="projects"
          :items="availableProjects"
          label="Projeto(s)"
        />
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="secondary"
          text
          class="ma-2 white--text"
          @click="save"
          :disabled="name === ''"
        >
          Salvar
        </v-btn>
        <v-btn color="primary" text class="ma-2 white--text" @click="dismiss">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'group-dialog',
  props: {
    onSave: {
      type: Function
    }
  },
  computed: {},
  data() {
    return {
      name: '',
      show: false,
      availableProjects: [],
      projects: []
    };
  },
  methods: {
    ...mapActions({
      _createGroup: 'createGroup'
    }),
    async open(project, allProjects) {
      this.show = true;
      this.projects = [];
      this.projects.push(project._id);
      this.availableProjects = allProjects.filter((p) => !p.group);
      this.availableProjects = this.availableProjects.map((project) => {
        project.humanReadableName = `${project.client} - ${
          project.ambient || ''
        }`;
        return project;
      });
    },
    async save() {
      try {
        await this._createGroup({ name: this.name, projects: this.projects });
        if (this.onSave) {
          this.onSave();
        }
      } catch (e) {
        alert('Algo deu errado. Por favor, tente novamente mais tarde.');
      }
      this.dismiss();
    },
    dismiss() {
      this.show = false;
    }
  }
};
</script>

<style scoped>
.v-dialog .v-card {
  padding-top: 20px;
}
.dialog_message {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  max-width: 340px;
  margin: 0 auto;
}
.v-card__actions {
  padding: 10px 0 30px;
  justify-content: center;
}
</style>
